.cart-product {
  position: relative;

  &__remove {
    font-size: 16px;
    text-shadow: 0 1px 0 #fff;
    position: absolute;
    top: 0;
    right: 0;
    transition: 0.3s;
    color: $primary;

    &:hover {
      color: $primary;
      text-decoration: none;
    }
  }

  &__frame {
    @include frame('rect', 100%);
    @include frame('fade', rgba(#fff, 0.4));
    width: 110px;
    margin-right: 10px;
  }

  &__title {
    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase;
    margin-right: 24px;
    margin-bottom: 2px;
  }

  &__info {
    font-size: 12px;
  }
}
