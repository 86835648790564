.menu {
  list-style: none;
  margin: 0;
  padding: 0;

  &-item {
    margin: 4px 0 8px;
    position: relative;
  }

  &-link {
    display: block;
    width: 100%;
    font-size: 14px;
    line-height: 40px;
    padding: 0 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: inherit;

    &:hover {
      color: inherit;
      text-decoration: none;
    }
  }

  &-toggle {
    display: inline-flex;
    width: 40px;
    height: 40px;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;

    span {
      display: inline-flex;
      width: 24px;
      height: 24px;
      justify-content: center;
      align-items: center;
      border-radius: 12px;
      transition: 0.3s;

      &:hover {
        background-color: rgba(#000, 0.1);
      }
    }

    &.active span {
      transform: rotate(90deg);
    }
  }

  &-sub {
    display: none;
  }

  &-sub &-link {
    padding-left: 40px;
  }

  &-sub-2 &-link {
    padding-left: 60px;
  }

  &-sub-3 &-link {
    padding-left: 80px;
  }

  &-item:hover > &-link {
    background-color: rgba($primary, 0.05);
  }

  &-root > &-item > &-link {
    font-weight: 700;
    text-transform: uppercase;
  }

  @media (min-width: #{$navbar-breakpoint}px) {
    &-sub {
      min-width: 160px;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
      position: absolute;
      z-index: 2;
      top: -4px;
      left: calc(100% + 4px);
      border-radius: 4px;
      transform-origin: 0 4px;
      background-color: $white;

      &::before {
        content: '';
        display: block;
        width: 8px;
        height: 100%;
        position: absolute;
        top: 0;
        right: 100%;
      }

      &-right {
        left: auto;
        right: calc(100% + 4px);
        transform-origin: 100% 0;

        &::before {
          right: auto;
          left: 100%;
        }
      }
    }

    &-sub &-link {
      padding-left: 20px;
    }

    &-root {
      display: flex;
    }

    &-root > &-item {
      margin: 0;
    }

    &-root > &-item > &-link {
      font-size: 16px;
    }

    &-root > &-item:hover > &-link {
      background: 0;
      color: $primary;
    }

    &-root > &-item-group > &-link {
      padding-right: 20px;
    }

    &-root > &-item-group > &-toggle {
      display: none;
    }

    &-root > &-item-group > &-sub {
      transform-origin: 0 0;
      top: calc(100% + 4px);
      left: 0;

      &::before {
        width: 100%;
        height: 8px;
        right: auto;
        left: 0;
        top: auto;
        bottom: 100%;
      }
    }

    &-item-group > &-link {
      padding-right: 40px;
    }

    &-item-group:hover > &-sub {
      display: block;
      animation: menu-float-show 0.2s ease-out;
    }
  }
}

@keyframes menu-float-show {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.h-menu {
  border-top: 1px solid $border-color;

  @media (min-width: 1200px) {
    display: flex;
    position: absolute;
    top: 0;
    right: 0;

    & > .menu-item {
      &:not(:last-child) {
        margin-right: 1px;
        position: relative;

        &::after {
          content: '';
          display: block;
          height: 14px;
          border-right: 1px solid $border-color;
          position: absolute;
          top: 50%;
          right: -1px;
          transform: translateY(-50%);
        }
      }
    }

    & > .menu-link {
      padding: 0 16px;
    }

    & > .menu-item > .menu-toggle {
      display: none;
    }

    & > .menu-item:hover > .menu-link {
      background: 0;
      color: $primary;
    }

    & > .menu-item-group > .menu-link {
      padding-right: 16px;

      &::after {
        content: '\f0d7';
        font-family: 'fontawesome';
        margin-left: 6px;
      }
    }

    & > .menu-item-group > .menu-sub {
      top: 100%;
      left: 0;
    }
  }
}
