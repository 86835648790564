.preview {
  &__thumbs {
    margin-top: 16px;
  }

  @media (max-width: 575px) {
    &__thumbs {
      margin-top: 8px;
    }
  }
}

.preview-slider {
  &__frame {
    @include frame('rect', 100%);
  }

  .swiper-slide {
    background-color: $white;
  }
}

.thumb-slider {
  position: relative;

  &__frame {
    @include frame('rect', 100%);
  }

  &.has-nav {
    padding: 0 20px;
  }

  &.has-nav &__prev,
  &.has-nav &__next {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 100%;
    font-size: 24px;
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;

    &:focus {
      outline: 0;
    }
  }

  &.has-nav &__next {
    left: auto;
    right: 0;
  }

  &:not(.has-nav) &__prev,
  &:not(.has-nav) &__next {
    display: none;
  }
}
