.md-download {
  &__body {
    padding: 60px 100px 40px;
    position: relative;
  }

  &__title {
    font-size: 30px;
    font-weight: 900;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 40px;
  }

  &__close {
    display: flex;
    width: 40px;
    height: 40px;
    position: absolute;
    top: 0;
    right: 0;

    &::before,
    &::after {
      content: '';
      display: block;
      width: 20px;
      border-top: 3px solid $gray-800;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) rotateZ(45deg);
    }

    &::after {
      transform: translate(-50%, -50%) rotateZ(-45deg);
    }
  }

  .modal-content {
    border-radius: 0;
  }

  @media (min-width: 1200px) {
    .modal-dialog {
      max-width: 1110px;
    }
  }

  @media (max-width: 1199px) {
    &__body {
      padding: 40px;
    }

    &__title {
      font-size: 20px;
      margin-bottom: 20px;
    }
  }

  @media (max-width: 767px) {
    &__body {
      padding: 40px 20px 30px;
    }

    &__title {
      font-size: 18px;
    }
  }
}

.md-video {
  &__body {
    padding: 0;
    position: relative;
  }

  &__close {
    display: flex;
    width: 30px;
    height: 30px;
    position: absolute;
    z-index: 10;
    top: 0;
    right: 0;
    transform: translate(50%, -50%);
    color: $white;
    background-color: $black;
    border: 2px solid $border-color;
    border-radius: 50%;
    box-shadow: 0 1px 3px rgba(#000, 0.3);

    &::before,
    &::after {
      content: '';
      display: block;
      width: 14px;
      height: 2px;
      background-color: $white;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) rotateZ(45deg);
    }

    &::after {
      transform: translate(-50%, -50%) rotateZ(-45deg);
    }
  }

  @media (min-width: 1199px) {
    .modal-dialog {
      max-width: 1020px;
    }
  }
}
