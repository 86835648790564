.section {
  overflow: hidden;
  padding: 40px 0 50px;

  &--border-top {
    border-top: 1px solid $lightpink;
  }

  &__title {
    text-align: center;
    font-size: 25px;
    font-family: $androgyne;
    position: relative;
    padding-top: 35px;
    margin-bottom: 25px;

    &::before {
      content: '';
      display: block;
      width: 64px;
      height: 32px;
      background: url('../images/title-icon.png') center/contain no-repeat;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  @media (max-width: 767px) {
    &__title {
      font-size: 20px;
    }
  }
}

.section-2 {
  padding: 30px 0 50px;

  &__title {
    font-size: 30px;
    font-weight: 700;
    text-align: center;
    margin-bottom: 20px;
  }
}

.section-3 {
  &__title {
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 16px;

    &::after {
      content: '';
      display: block;
      flex: 1;
      border-top: 1px solid $lightpink;
      margin-left: 20px;
    }
  }
}

.form {
  border: 1px solid $lightpink;
  padding: 20px;

  &__title {
    font-size: 30px;
    font-weight: 900;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 30px;
  }

  &__footer {
    border-top: 1px solid $lightpink;
    text-align: center;
    padding-top: 20px;
    margin-top: 20px;

    a {
      color: $primary;
      font-weight: 700;
      text-transform: uppercase;
    }
  }

  @media (max-width: 1199px) {
    &__title {
      font-size: 24px;
      margin-bottom: 20px;
    }
  }

  @media (max-width: 767px) {
    &__title {
      font-size: 20px;
    }
  }
}

.cart {
  border: 1px solid $lightpink;
  border-top: 0;

  &__frame {
    @include frame('rect', 100%);
    width: 80px;
    margin: 0 auto;
  }

  &__name {
    font-size: 16px;
    font-weight: 700;
    min-width: 150px;
  }

  &__price {
    font-size: 18px;
    color: $primary;
    text-align: center;
    white-space: nowrap;
  }

  &__total {
    font-size: 16px;
    text-align: right;
    padding: 15px 0;
  }

  &__select {
    width: auto;
  }

  th {
    text-align: center;
    white-space: nowrap;
  }

  th,
  td {
    border-color: $lightpink;
  }

  tbody tr:hover {
    background-color: $lighterpink;
  }
}

.cart-btns {
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;

  &__btn {
    display: inline-block;
    font-size: 14px;
    line-height: 30px;
    padding: 3px 20px 0;
    border-radius: 18px;
    border: 1px solid $lightpink;
    color: $gray-800;
    text-transform: uppercase;
    font-weight: 700;

    &:hover {
      color: $gray-800;
      text-decoration: none;
    }

    &:not(:last-child) {
      margin-right: 20px;
    }

    &--dark {
      background-color: $dark;
      border-color: $dark;
      color: $white;

      &:hover {
        color: $white;
      }
    }

    &--primary {
      background-color: $primary;
      border-color: $primary;
      color: $white;

      &:hover {
        color: $white;
      }
    }
  }

  @media (max-width: 767px) {
    &__btn {
      font-size: 13px;
      padding: 3px 12px 0;

      &:not(:last-child) {
        margin-right: 10px;
      }
    }
  }
}

.aside {
  &__title {
    font-size: 16px;
    font-weight: 700;
    color: $white;
    text-align: center;
    text-transform: uppercase;
    background-color: $primary;
    padding: 10px;
    margin-bottom: 0;
  }

  &__body {
    padding: 16px;
    border: 1px solid $lightpink;
  }
}
