.design {
  display: flex;
  flex-direction: column;
  width: 100vw;
  max-width: 100%;
  height: 100vh;
  position: relative;
  z-index: 1;
  overflow: hidden;

  &__header {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    flex: 0 0 50px;
    height: 50px;
    background-color: $primary;
  }

  &__home {
    color: $white;
    font-size: 16px;

    &:hover {
      color: $white;
      text-decoration: none;
    }
  }

  &__widgets {
    display: flex;
  }

  &__widget {
    display: inline-block;
    background-color: $white;
    font-size: 14px;
    line-height: 35px;
    padding: 0 12px;
    border-radius: 4px;
    color: $gray-800;
    box-shadow: 0 1px 3px rgba(#000, 0.3);
    font-weight: 700;
    font-family: Arial, Helvetica, sans-serif;

    &:hover {
      color: $gray-700;
      text-decoration: none;
    }

    &:not(:last-child) {
      margin-right: 8px;
    }
  }

  &__body {
    display: flex;
    flex: 1;
  }

  &__aside {
    display: flex;
    @include shadow("xs");
  }

  &__wrapper {
    flex: 1;
    overflow: auto;
    position: relative;
  }

  &__bar {
    width: 100%;
    height: 50px;
    background-color: $white;
    position: absolute;
    top: 0;
    left: 0;
    @include shadow("xs");
  }

  &__content {
    width: 100%;
    height: calc(100% - 50px);
    position: absolute;
    top: 50px;
    left: 0;
    overflow: auto;
  }

  &__inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 100%;
    margin: 0 auto;
    padding: 70px 0 150px;
  }

  &__btn {
    display: block;
    text-align: center;
    font-size: 14px;
    font-weight: 700;
    padding: 8px 12px;
    border: 1px solid $border-color;
    background-color: rgba(#fff, 0.8);

    &:hover {
      background-color: rgba(#fff, 0.95);
      text-decoration: none;
    }
  }
}

.design-tabs {
  flex-direction: column;
  background-color: #0e1318;

  .nav-link {
    display: flex;
    width: 72px;
    height: 72px;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;
    color: $white;
    font-size: 11px;
    cursor: pointer;
    opacity: 0.5;

    svg {
      height: 24px;
      margin-bottom: 4px;
    }

    &:hover {
      opacity: 1;
    }

    &.active {
      background-color: #293039;
      opacity: 1;
    }
  }
}

.design-panels {
  width: 350px;
  background-color: #293039;

  &__item {
    display: flex;
    height: 100%;
    flex-direction: column;
    position: relative;
  }

  &__body {
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: scroll;
    padding: 16px;
    position: absolute;
    top: 0;
    left: 0;
  }

  &__grid {
    columns: 2;
    column-gap: 8px;

    &--3 {
      columns: 3;
    }
  }

  &__label {
    font-size: 12px;
    color: $white;
    opacity: 0.8;
    margin: 12px 0 8px;
    font-weight: 700;
  }

  &__upload {
    display: inline-block;
    margin: 0;
    position: relative;
    overflow: hidden;
    background-color: $white;
    border-radius: 4px;
    line-height: 30px;
    padding: 0 12px;
    font-family: Arial, Helvetica, sans-serif;

    input {
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  &__input {
    border: 0;
    border-radius: 4px;
    resize: none;
  }

  &__size {
    background-color: $white;
    position: relative;
    border-radius: 4px;
    break-inside: avoid-column;
    margin-bottom: 8px;

    &::before {
      content: "";
      display: block;
      width: 100%;
      padding-bottom: 100%;
    }

    span {
      display: flex;
      width: 100%;
      height: 100%;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      font-size: 18px;
      cursor: pointer;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}

.design-item {
  display: block;
  cursor: pointer;
  break-inside: avoid-column;
  margin-bottom: 8px;
  position: relative;

  img {
    display: block;
    width: 100%;
  }

  &__delete {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 10;
    color: $white;
    font-size: 12px;
    font-weight: 700;
    line-height: 20px;
    padding: 0 8px;
    border-radius: 10px;
    background-color: rgba(#000, 0.5);
    transition: .3s;

    &:hover {
      color: $white;
      text-decoration: none;
      background-color: $danger;
    }
  }
}

.design-texture {
  display: block;
  cursor: pointer;
  break-inside: avoid-column;
  margin-bottom: 8px;
  background-color: rgba(#fff, 0.6);
  padding: 10px;

  img {
    display: block;
    width: 100%;
  }
}

.design-tools {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  position: absolute;
  top: 0;
  left: 0;

  &__common {
    display: none;
  }

  &__item {
    color: $gray-800;
    font-weight: 700;

    &:hover {
      color: $gray-800;
      text-decoration: none;
    }

    &:not(:last-child) {
      margin-right: 14px;
    }

    &--lock {
      color: #999;

      .fa-lock {
        display: none;
      }

      &:hover {
        color: #999;
      }

      &.lock {
        color: $gray-800;

        .fa-lock {
          display: inline-block;
        }

        .fa-unlock {
          display: none;
        }
      }
    }
  }

  &__image,
  &__text {
    display: none;
  }

  &__origin {
    font-size: 16px;
    font-weight: 700;
    color: $danger;
  }

  &__specific.image &__image {
    display: block;
  }

  &__specific.text &__text {
    display: block;
  }

  &__specific.background &__background {
    display: block;
  }

  &__specific.image &__origin,
  &__specific.text &__origin,
  &__specific.background &__origin {
    display: none;
  }
}

.design-fonts {
  display: flex;
  flex-direction: column;

  &__item {
    display: flex;
    align-items: center;
    overflow: hidden;
    position: relative;
    height: 40px;
    padding: 0 16px;

    img {
      height: 24px;
    }

    &:hover {
      background-color: $light;
    }

    &.active {
      padding-right: 40px;
      background-color: $light;

      &::after {
        content: "";
        display: block;
        width: 24px;
        height: 24px;
        background: url("../images/icon-checked.svg") center/contain no-repeat;
        position: absolute;
        top: 50%;
        right: 16px;
        transform: translateY(-50%);
      }
    }
  }
}

.board {
  margin-bottom: 30px;
  position: relative;

  &__header {
    display: flex;
    justify-content: flex-end;
    padding: 4px 0;
  }

  &__btn {
    display: inline-flex;
    width: 28px;
    height: 28px;
    justify-content: center;
    align-items: center;
    color: #666;
    border-radius: 4px;

    &:hover {
      color: #000;
      background-color: rgba(#000, 0.08);
    }

    &:not(:last-child) {
      margin-right: 4px;
    }

    svg {
      height: 20px;
    }
  }

  &__body {
    width: 454px;
    height: 605px;
    background-color: $white;
    box-shadow: 0 1px 3px rgba(#000, 0.3);
    position: relative;
    z-index: 1;
    overflow: hidden;
  }
}

.box {
  display: inline-block;
  user-select: none;
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;

  &__wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &__text {
    padding: 0 10px;
    line-height: 1;
    white-space: nowrap;
    text-align: center;
  }

  &__image {
    position: relative;
    overflow: hidden;

    &::before {
      content: '';
      display: block;
      width: 100%;
      padding-bottom: 100%;
    }

    img {
      display: block;
      width: 100%;
      height: 100%;
      max-width: unset;
      position: absolute;
      top: 0;
      left: 0;
    }

    &.circle {
      border-radius: 50%;
      height: auto !important;
    }

    &.square {
      height: auto !important;
    }

    &.cover img {
      object-fit: cover;
    }

    &.contain img {
      object-fit: contain;
    }

    &.fit img {
      object-fit: unset;
    }
  }

  &--bg {
    width: 100%;
    height: 100%;
    z-index: -2 !important;
  }

  &__bg {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: 50% 50%;
      max-width: unset;
    }
  }
}

.skeleton {
  display: none;
  border: 2px solid #00d9e1;
  cursor: pointer;
  position: absolute;
  z-index: 99999;
  pointer-events: none;
  user-select: none;
  transform-origin: 0 0;
  transform: translate(-50%, -50%);

  &__resize {
    display: block;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: $white;
    box-shadow: 0 1px 3px rgba(#000, 0.8);
    cursor: pointer;
    position: absolute;
    top: 100%;
    left: 100%;
    transform: translate(-50%, -50%);
    pointer-events: all;

    &--topleft {
      top: 0;
      left: 0;
    }

    &--topright {
      top: 0;
      left: 100%;
    }

    &--bottomright {
      top: 100%;
      left: 100%;
    }

    &--bottomleft {
      top: 100%;
      left: 0;
    }
  }

  &__rotate {
    display: block;
    width: 20px;
    height: 20px;
    border: 2px solid #fff;
    border-radius: 50%;
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translate(-50%, 10px);
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24'%3E%3Cpath fill='currentColor' d='M15.25 18.48V15a.75.75 0 1 0-1.5 0v4c0 .97.78 1.75 1.75 1.75h4a.75.75 0 1 0 0-1.5h-2.6a8.75 8.75 0 0 0-2.07-15.53.75.75 0 1 0-.49 1.42 7.25 7.25 0 0 1 .91 13.34zM8.75 5.52V9a.75.75 0 0 0 1.5 0V5c0-.97-.78-1.75-1.75-1.75h-4a.75.75 0 0 0 0 1.5h2.6a8.75 8.75 0 0 0 2.18 15.57.75.75 0 0 0 .47-1.43 7.25 7.25 0 0 1-1-13.37z'/%3E%3C/svg%3E");
    background-size: contain;
    background-position: center;
    background-color: $white;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.8);
    pointer-events: all;
    cursor: pointer;
  }

  &.lock &__resize,
  &.lock &__rotate {
    display: none !important;
  }
}

.pickr {
  .pcr-button {
    width: 35px;
    height: 35px;
    border: 1px solid $border-color;
    border-radius: 4px;
  }
}

.size-input {
  .input-group-text {
    background-color: $white;
    border: 0;
    color: #999;
  }

  .form-control {
    border: 0;
    text-align: right;
    font-weight: 700;
    border-radius: 0 4px 4px 0;
    padding-right: 16px;
  }
}
