.pagination {
  margin-bottom: 0;

  .page-item {
    margin-right: 8px;

    &:last-child {
      margin-right: 0;
    }
  }

  .page-link {
    color: $gray-800;
    padding: 8px 16px;
  }
}

.breadcrumb {
  padding: 15px 0;

  &-item {
    &,
    & + &::before,
    &.active,
    &.active::before,
    a,
    a:hover {
      color: $gray-800;
    }
  }
}

.phone-ring {
  display: flex;
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
  color: #fff;
  border-radius: 50%;
  background-color: #e73a35;
  position: fixed;
  z-index: 100;
  bottom: 50px;
  left: 50px;
  box-shadow: 0 5px 20px rgba(#000, 0.3);

  &:hover {
    color: $white;
  }

  &::before {
    content: "";
    display: block;
    width: 250%;
    height: 250%;
    border-radius: 50%;
    border: 1px solid #e73a35;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    animation: phone-circle-animate 1s ease-in-out infinite;
  }

  &::after {
    content: "";
    display: block;
    width: 160%;
    height: 160%;
    border-radius: 50%;
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: -1;
    transform: translate(-50%, -50%);
    background-color: rgba(#e73a35, 0.5);
    animation: phone-circle-fill-animate 2s ease-in-out infinite;
  }

  &__icon {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    background-color: rgba(#e73a35, 0.7);
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: 0;
    animation: phone-icon-animate 1s ease-in-out infinite;
  }

  &__text {
    display: inline-block;
    background-color: rgba(#e73a35, 0.7);
    font-size: 14px;
    font-weight: 700;
    line-height: 40px;
    padding: 0 20px 0 60px;
    color: $white;
    border-radius: 20px;
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    box-shadow: 0 5px 20px rgba(#000, 0.3);
  }

  &:hover &__text {
    color: #e73a35;
    background-color: rgba(#fff, 0.7);
  }

  @media (max-width: 767px) {
    left: 20px;
    bottom: 20px;

    &__text {
      display: none;
    }
  }
}

@keyframes phone-circle-animate {
  0% {
    transform: translate(-50%, -50%) rotate(0) scale(0.5);
    opacity: 0.1;
  }
  30% {
    transform: translate(-50%, -50%) rotate(0) scale(0.7);
    opacity: 0.5;
  }
  100% {
    transform: translate(-50%, -50%) rotate(0) scale(1);
    opacity: 0.1;
  }
}

@keyframes phone-circle-fill-animate {
  0%,
  100% {
    transform: translate(-50%, -50%) rotate(0) scale(0.7);
    opacity: 0.2;
  }
  50% {
    transform: translate(-50%, -50%) rotate(0) scale(1);
    opacity: 0.2;
  }
}

@keyframes phone-icon-animate {
  0%,
  100% {
    transform: rotate(0) scale(1) skew(1deg);
  }
  10% {
    transform: rotate(-25deg) scale(1) skew(1deg);
  }

  20%,
  40% {
    transform: rotate(25deg) scale(1) skew(1deg);
  }
  30% {
    transform: rotate(-25deg) scale(1) skew(1deg);
  }
  50% {
    transform: rotate(0) scale(1) skew(1deg);
  }
}

.button {
  display: inline-block;
  border: 0;
  background: $primary;
  color: $white;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  padding: 2px 20px 0;
  line-height: 33px;
  border-radius: 18px;

  &:hover {
    color: $white;
    text-decoration: none;
    background-color: darken($primary, 5%);
  }
}

.news {
  display: flex;
  height: 100%;
  flex-direction: column;
  @include shadow("xs");

  &__frame {
    @include frame("rect", 56.25%);
    @include frame("zoom");
  }

  &__body {
    padding: 12px;
    flex-grow: 1;
  }

  &__info {
    color: #666;
    margin-bottom: 6px;
  }

  &__title {
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 8px;
  }

  &__desc {
    @include max-line(3);
    color: #666;
  }
}

.welcome {
  padding: 40px 0 50px;
  position: relative;
  z-index: 1;
  background: linear-gradient(
    to bottom right,
    transparent 0%,
    transparent 49.99%,
    $lighterpink 50%,
    $lighterpink 100%
  );

  &::before {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    background: linear-gradient(
      to bottom left,
      transparent 0%,
      transparent 49.99%,
      $lighterpink 50%,
      $lighterpink 100%
    );
  }

  &__content {
    width: 725px;
    max-width: 100%;
    margin: 0 auto;
    background-color: $white;
    padding: 140px 130px 20px;
    @include shadow("");
    background: url("../images/welcome-bg.jpg") no-repeat;
    background-size: 100% 100%;
  }

  &__subtitle {
    font-family: $androgyne;
    text-align: center;
    font-size: 18px;
    margin-bottom: 10px;
    color: #707070;
  }

  &__title {
    font-size: 30px;
    font-weight: 900;
    text-align: center;
    text-transform: uppercase;
    color: $primary;
    margin-bottom: 4px;
  }

  &__desc {
    text-align: center;
    color: #707070;
    margin-bottom: 20px;
  }

  &__link {
    display: inline-block;
    border-bottom: 1px solid $primary;
    line-height: 1.2;

    &:hover {
      color: $primary;
      text-decoration: none;
    }
  }

  @media (max-width: 767px) {
    &__content {
      padding: 150px 20px 20px;
    }
  }

  @media (max-width: 575px) {
    &__content {
      background-size: cover;
      background-position: center;
    }
  }
}

.feature {
  &__frame {
    display: flex;
    height: 124px;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;

    img {
      max-height: 100%;
      object-fit: contain;
      object-position: 50% 50%;
    }
  }

  &__title {
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 12px;
  }

  &__desc {
    text-align: center;
    max-width: 275px;
    margin: 0 auto;
  }
}

.home-tabs {
  display: block;
  white-space: nowrap;
  overflow: auto;
  text-align: center;

  .nav-item {
    display: inline-block;

    &:not(:last-child) {
      margin-right: 50px;
    }
  }

  .nav-link {
    min-width: 145px;
    font-size: 14px;
    line-height: 30px;
    padding: 3px 20px 0;
    border-radius: 18px;
    border: 1px solid $gray-800;
    color: $gray-800;

    &.active {
      font-weight: 700;
      color: $primary;
      border-color: $primary;
    }
  }

  @media (max-width: 767px) {
    .nav-item {
      &:not(:last-child) {
        margin-right: 16px;
      }
    }

    .nav-link {
      min-width: unset;
    }
  }
}

.grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: auto;

  &__item {
    position: relative;
    overflow: hidden;
  }

  &__frame {
    @include frame("rect", 100%);
    height: 100%;
  }

  &__content {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    background-color: $lighterpink;
    padding: 30px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    opacity: 0;
    transform: translateY(30%);
    transition: 0.4s ease-out;
  }

  &__item:hover &__content {
    transform: translateY(0);
    opacity: 1;
  }

  &__title {
    font-size: 18px;
    font-weight: 700;
    text-align: center;
    margin-bottom: 20px;
  }

  &__desc {
    text-align: center;
    margin-bottom: 20px;
  }

  &__link {
    @include btn("fill");
    display: inline-block;
    background-color: $primary;
    line-height: 33px;
    padding: 2px 20px 0;
    border-radius: 18px;
    color: $white;
    text-transform: uppercase;
    font-weight: 600;
    border: 1px solid $primary;

    &:hover {
      color: $primary;
      text-decoration: none;
    }

    &::before {
      background-color: $white;
    }
  }

  &__item:nth-child(3),
  &__item:nth-child(4) {
    .grid__content {
      grid-column: 1 / span 1;
      grid-row: 1 / span 1;
    }
  }

  @media (max-width: 1199px) {
    &__content {
      padding: 16px;
    }

    &__title {
      font-size: 14px;
      margin-bottom: 8px;
    }

    &__desc {
      font-size: 12px;
      margin-bottom: 8px;
    }

    &__link {
      font-size: 12px;
    }
  }

  @media (max-width: 767px) {
    grid-template-columns: 1fr 1fr;

    &__item:nth-child(2) {
      .grid__content {
        grid-column: 1 / span 1;
        grid-row: 1 / span 1;
      }
    }

    &__item:nth-child(3) {
      .grid__content {
        grid-column: 2 / span 1;
        grid-row: 1 / span 1;
      }
    }
  }

  @media (max-width: 575px) {
    &__item {
      grid-template-columns: 1fr;
    }

    &__item:nth-child(2),
    &__item:nth-child(3),
    &__item:nth-child(4) {
      .grid__content {
        grid-column: 1 / span 1;
        grid-row: 2 / span 1;
      }
    }

    &__desc {
      display: none;
    }
  }
}

.video {
  position: relative;

  &__frame {
    @include frame("rect", 56.25%);
  }

  &__overlay {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    position: absolute;
    z-index: 10;
    top: 0;
    left: 0;
    background-color: rgba(#000, 0.15);
    transition: 0.3s;

    &:hover {
      text-decoration: none;
      background-color: rgba(#000, 0.4);
    }

    .fa {
      color: $youtube;
    }
  }
}

.cta {
  background-color: $lighterpink;
  padding: 45px 0;

  &__title {
    font-family: $androgyne;
    font-size: 25px;
    margin-bottom: 16px;
  }

  &__btn-wrap {
    margin-left: 30px;
  }

  &__btn {
    display: inline-block;
    font-size: 20px;
    font-weight: 700;
    color: $white;
    background-color: $primary;
    line-height: 50px;
    padding: 0 30px;
    border-radius: 25px;
    text-transform: uppercase;

    &:hover {
      color: $white;
      text-decoration: none;
      background-color: darken($primary, 5%);
    }
  }

  @media (max-width: 991px) {
    padding: 24px 0;

    &__inner {
      flex-direction: column;
    }

    &__title {
      text-align: center;
      font-size: 20px;
      margin-bottom: 8px;
    }

    &__desc {
      text-align: center;
    }

    &__btn-wrap {
      margin-left: 0;
      margin-top: 16px;
    }

    &__btn {
      font-size: 14px;
      line-height: 33px;
      padding: 2px 20px 0;
    }
  }
}

.customer-comments {
  max-width: 880px;
  margin: 0 auto 16px;

  &__slide {
    padding: 16px 30px;
    position: relative;

    &:focus {
      outline: 0;
    }

    &::after {
      content: "";
      display: block;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 50px;
      border-bottom: 4px solid $lightpink;
    }
  }

  &__open-quote {
    font-size: 26px;
    position: absolute;
    top: 0;
    left: 0;
  }

  &__close-quote {
    font-size: 26px;
    position: absolute;
    bottom: 0;
    right: 0;
  }

  &__detail {
    font-style: italic;
    text-align: center;
  }
}

.customer-avatars {
  max-width: 450px;
  margin: 0 auto;
  position: relative;

  &__slide {
    padding: 10px 0;
    transition: 0.3s;
    z-index: 1;
    transform: scale(0.8);

    &:focus {
      outline: 0;
    }

    &.slick-center {
      position: relative;
      transform: scale(1);
      z-index: 2;
    }
  }

  &__iwrap {
    @include frame("rect", 100%);
    width: 140px;
    max-width: 100%;
    border-radius: 50%;
    overflow: hidden;
    background-color: $white;
    padding: 6px;
    border: 1px solid $lightpink;

    img {
      border: 6px solid $white;
      border-radius: 50%;
    }
  }
}

.customer-info {
  max-width: 500px;
  margin: 0 auto;
  text-align: center;

  &__slide {
    &:focus {
      outline: 0;
    }
  }

  &__name {
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 8px;
  }
}

.banner {
  display: flex;
  height: 350px;
  flex-direction: column;
  justify-content: center;
  position: relative;
  z-index: 1;

  // &::before {
  //   content: '';
  //   display: block;
  //   width: 100%;
  //   height: 100%;
  //   background-color: rgba(#000, 0.25);
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  //   z-index: -1;
  // }

  &__bg {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 50% 50%;
    position: absolute;
    z-index: -2;
    top: 0;
    left: 0;
  }

  &__title {
    font-size: 30px;
    font-weight: 700;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: -5px;
  }

  &__breadcrumb {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &--white &__title {
    color: $white;
  }

  &--white &__breadcrumb {
    .breadcrumb-item {
      &,
      & + .breadcrumb-item::before,
      &.active,
      &.active::before,
      a,
      a:hover {
        color: $white;
      }
    }
  }
}

.product {
  &__frame {
    @include frame("rect", 100%);
    @include frame("shine");
  }

  &__title {
    font-size: 16px;
    font-weight: 700;
    text-align: center;
    margin-top: 16px;
    margin-bottom: 0;
  }

  @media (max-width: 767px) {
    &__title {
      font-size: 14px;
      margin-top: 8px;
    }
  }
}

.pd-info {
  &__title {
    font-size: 30px;
    font-weight: 700;
    text-transform: uppercase;
  }

  &__desc {
    p:not(:last-child) {
      margin-bottom: 8px;
    }
  }

  &__btn {
    display: inline-block;
    font-size: 18px;
    font-weight: 700;
    text-transform: uppercase;
    color: $white;
    padding: 3px 50px 0;
    line-height: 55px;
    border-radius: 30px;
    background-color: $primary;

    &:hover {
      color: $white;
      text-decoration: none;
      background-color: darken($primary, 5%);
    }
  }

  &__hotline {
    a {
      font-weight: 700;
    }
  }

  &__hr {
    border-color: $lightpink;
  }

  &__price {
    display: inline-block;
    padding-top: 3px;
    font-size: 30px;
    font-weight: 700;
    color: $primary;
    margin-left: 60px;
  }

  @media (max-width: 767px) {
    &__price {
      margin-left: 20px;
      font-size: 22px;
    }

    &__btn {
      line-height: 40px;
      font-size: 16px;
      padding: 0 40px;
    }
  }
}

.payments {
  display: flex;

  &__item {
    display: flex;
    justify-content: center;
    align-items: center;

    &:not(:last-child) {
      margin-right: 14px;
    }
  }
}

.pd-detail {
  &__title {
    font-size: 15px;
    font-weight: 900;
    color: $primary;
    text-transform: uppercase;
    border-bottom: 1px solid $lightpink;
    padding-bottom: 4px;
    margin-bottom: 16px;
  }
}

.post {
  &-title {
    font-size: 25px;
    font-weight: 700;
  }

  &-info {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    color: #666;
    margin: 10px 0 20px;
  }
}

.relate-policy {
  &__title {
    font-size: 25px;
    font-weight: 900;
    margin-bottom: 8px;
  }

  &__list {
    padding-left: 17px;
    margin-bottom: 0;
  }

  &__list-item {
    &:not(:last-child) {
      margin-bottom: 6px;
    }
  }

  &__link {
    display: inline-block;
    color: $gray-800;

    &:hover {
      color: $primary;
      text-decoration: none;
    }
  }
}

.download {
  &__frame {
    @include frame("rect", 100%);
  }

  &__title {
    text-align: center;
    font-size: 16px;
    font-weight: 700;
    margin-top: 10px;
  }

  &__link-wrap {
    text-align: center;
    padding: 10px 0 14px;
  }

  &__link {
    color: $primary;

    &:hover {
      color: $primary;
      text-decoration: none;
    }
  }
}

.contact {
  overflow: hidden;
  position: relative;
  z-index: 1;

  &__img {
    max-height: 80%;
    position: absolute;
    z-index: -1;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
  }

  &__form {
    padding: 90px 0 150px;
  }

  &__title {
    font-size: 25px;
    font-weight: 900;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 10px;
  }

  &__desc {
    text-align: center;
    margin-bottom: 20px;
  }

  @media (max-width: 1440px) {
    &__form {
      max-width: 700px;
    }
  }

  @media (max-width: 1199px) {
    &__img {
      display: none;
    }

    &__form {
      padding: 60px 0;
      margin: 0 auto;
    }
  }
}

.as-menu {
  list-style: none;
  margin: -16px 0;
  padding-left: 0;

  &__item {
    &:not(:last-child) {
      border-bottom: 1px solid $lightpink;
    }
  }

  &__link {
    display: inline-block;
    font-size: 14px;
    color: $gray-800;
    padding: 7px 4px 6px;

    &:hover {
      color: $primary;
      text-decoration: none;
    }

    &.active {
      color: $primary;
      font-weight: 700;
    }
  }
}

.panel {
  border: 1px solid $lightpink;
  border-radius: 10px;

  &__header {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    border-radius: 10px 10px 0 0;
    border-bottom: 1px solid $lightpink;
    padding: 7px 12px;
    background-color: $lighterpink;
  }

  &__body {
    padding: 12px;
  }
}

.sticky {
  position: fixed;
  bottom: 25px;
  left: 25px;
  z-index: 100;

  &__toggle-text {
    transition: 0.3s;
  }

  &.active &__toggle-text {
    transform: translateX(-10px) translateY(-50%);
    opacity: 0;
  }

  &__list {
    margin-bottom: 10px;
    transition: 0.4s;
    transform: rotateX(90deg);
    opacity: 0;
    transform-origin: bottom;
  }

  &.active &__list {
    transform: rotateX(0);
    opacity: 1;
  }

  &__item {
    display: flex;
    color: $white;
    position: relative;

    &:hover {
      color: $white;
    }

    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }

  &__icon {
    display: flex;
    width: 40px;
    height: 40px;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: #1296d5;

    svg {
      width: 25px;
      height: 25px;
    }

    &--green {
      background-color: #00b151;
    }

    &--orange {
      background-color: #f7a400;
    }
  }

  &__text {
    position: absolute;
    top: 50%;
    left: calc(100% + 10px);
    transform: translateY(-50%);
    background-color: rgba(#1c4680, 0.8);
    border-radius: 4px;
    color: $white;
    white-space: nowrap;
    line-height: 35px;
    padding: 0 10px;

    &::before {
      content: "";
      display: block;
      border: 6px solid transparent;
      border-right-color: rgba(#1c4680, 0.8);
      position: absolute;
      top: 50%;
      right: 100%;
      transform: translateY(-50%);
    }
  }

  &.active &__toggle &__icon {
    position: relative;

    svg {
      display: none;
    }

    &::before,
    &::after {
      content: "";
      display: block;
      width: 20px;
      height: 2px;
      background-color: $white;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) rotate(45deg);
    }

    &::after {
      transform: translate(-50%, -50%) rotate(-45deg);
    }
  }
}
