body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  font-family: 'Lato', Arial, Helvetica, sans-serif;

  &.bg-grey {
    background-color: #edf0f2;
  }
}

img {
  max-width: 100%;
}

button {
  cursor: pointer;
}

a,
button,
input,
textarea,
select {
  &:focus {
    outline: 0;
  }
}

p:last-child {
  margin-bottom: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  a {
    @include text('default');

    &:hover {
      color: $primary;
    }
  }
}

.page {
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  &__content {
    flex-grow: 1;
  }
}
