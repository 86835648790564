.product-slider,
.video-slider {
  position: relative;

  &__prev,
  &__next {
    font-size: 30px;
    cursor: pointer;
    position: absolute;
    z-index: 10;
    top: 50%;
    left: -30px;
    transform: translateY(-50%);

    &:focus {
      outline: 0;
    }
  }

  &__next {
    left: auto;
    right: -30px;
  }

  @media (max-width: 575px) {
    &__prev {
      left: -15px;
    }

    &__next {
      right: -15px;
    }
  }
}

.banner-slider {
  position: relative;

  &__prev,
  &__next {
    display: flex;
    width: 40px;
    height: 40px;
    justify-content: center;
    align-items: center;
    border: 1px solid $white;
    border-radius: 50%;
    color: $white;
    padding: 2px;
    cursor: pointer;
    font-size: 20px;
    position: absolute;
    top: 50%;
    left: 16px;
    z-index: 10;
    transform: translateY(-50%);

    &:focus {
      outline: 0;
    }
  }

  &__next {
    left: auto;
    right: 16px;
  }

  &__frame {
    img {
      display: block;
      width: 100%;
    }
  }
}
