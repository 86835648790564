.footer {
  &__map {
    height: 320px;
  }

  &__light {
    padding: 30px 0 0;
  }

  &__dark {
    padding: 30px 0 0;
    background-color: #232d37;
  }

  &__title {
    font-size: 18px;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 10px;
  }

  &__title-2 {
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
    color: $white;
    margin-bottom: 10px;
  }

  &__content {
    color: $white;
  }
}

.f-form {
  &__label {
    margin-bottom: 8px;
  }

  &__btn {
    display: inline-block;
    color: $white;
    background: $primary;
    font-size: 14px;
    text-transform: uppercase;
    line-height: 35px;
    padding: 0 12px;
    border-radius: 18px;
    border: 0;
  }

  .form-control {
    height: 40px;
    border-radius: 8px;
  }
}

.f-menu {
  list-style: none;
  margin-bottom: 0;
  padding-left: 0;

  &__item {
    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }

  &__link {
    display: inline-block;
    color: $gray-800;

    &:hover {
      color: $primary;
      text-decoration: none;
    }
  }
}

.f-socials {
  display: flex;
  list-style: none;
  margin-bottom: 0;
  padding-left: 0;

  &__item {
    &:not(:last-child) {
      margin-right: 10px;
    }
  }

  &__link {
    display: flex;
    width: 24px;
    height: 24px;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    border: 1px solid $black;
    color: $black;
    font-size: 12px;

    &:hover {
      color: $black;
      text-decoration: none;
    }
  }
}

.f-contact {
  color: $white;

  &__label {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 12px;
  }

  &__item {
    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }
}

.f-hotline {
  &__label {
    font-size: 14px;
    margin-bottom: 8px;

    a {
      font-size: 19px;
      color: #ce151c;

      &:hover {
        color: #ce151c;
        text-decoration: none;
      }
    }
  }

  &__content {
    font-size: 14px;
    line-height: 26px;
  }
}
