.header {
  border-bottom: 1px solid $lightpink;
  position: relative;
  z-index: 100;

  &__inner {
    display: flex;
    align-items: center;
    position: relative;
    height: 120px;
  }

  &__logo {
    display: inline-flex;
    margin-right: auto;

    img {
      height: 77px;
    }
  }

  &__btns {
    display: flex;
  }

  &__btn {
    display: flex;
    width: 35px;
    height: 35px;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    color: $gray-800;
    border: 0;
    background: 0;

    &:hover {
      color: $gray-800;
      text-decoration: none;
    }

    &:not(:first-child) {
      margin-left: 8px;
    }

    img {
      max-width: 100%;
      max-height: 100%;
      object-fit: contain;
      object-position: 50% 50%;
    }
  }

  @media (min-width: 1200px) {
    &__elements {
      display: flex;
      margin-top: 35px;
    }

    &__btns {
      margin-left: 16px;
    }

    &__btn {
      &:not(:first-child) {
        margin-left: 16px;
      }
    }
  }
}

.search {
  display: none;
  width: 100%;
  padding: 10px 20px;
  background-color: $white;
  @include shadow('xs');
  position: fixed;
  z-index: 200;
  top: 0;
  left: 0;

  .form-control {
    border-right: 0;
    border-radius: 0;
  }

  .input-group-text {
    background-color: $primary;
    border-color: $primary;
    color: $white;
    border-radius: 0;
  }

  @media (min-width: 1200px) {
    width: 300px;
    padding: 0;
    box-shadow: unset;
    position: absolute;
    top: 100%;
    left: auto;
    right: 0;
  }
}
