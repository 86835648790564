.order {
  &__title {
    font-size: 20px;
    font-weight: 700;
    text-transform: uppercase;
    border-bottom: 1px solid $border-color;
    color: $primary;
    margin-bottom: 16px;
    padding-bottom: 16px;
  }

  &__subtitle {
    font-size: 14px;
    padding: 8px 20px;
    font-weight: 700;
    text-transform: uppercase;
    border-left: 8px solid $primary;
    background-color: rgba($primary, 0.2);
    margin-bottom: 20px;
  }

  &__list {
    @include list('solid', 8px);
    max-height: 210px;
    overflow: auto;
    padding-bottom: 10px;

    & > li {
      padding-right: 8px;
    }
  }

  &__total {
    padding: 14px 22px;
    text-align: right;
    font-weight: 700;
    border-top: 1px solid #ccc;
  }

  .form-group {
    margin-bottom: 20px;
  }

  .form-control {
    border-radius: 0;
  }

  @media (max-width: 767px) {
    padding: 20px 16px 0;
  }
}

.bank-select {
  display: none;
  padding-bottom: 30px;

  &__label {
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 4px;
  }

  &__grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: auto;
    grid-gap: 2px;
  }

  &__item {
    display: block;
    position: relative;
    border: 1px solid #ccc;
    margin: 0;
    cursor: pointer;

    &::before {
      content: '';
      display: block;
      width: 100%;
      padding-bottom: 50%;
    }
  }

  &__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 50% 50%;
    position: absolute;
    top: 0;
    left: 0;
  }

  &__input {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    z-index: -1;
  }

  &__input:checked + &__img {
    box-shadow: 0 0 0 2px $primary;
  }
}
